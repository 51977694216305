interface CountryState {
	areaList: any[]; // 需要业务相关人员补充，比如下列any类型，需要替换成具体类型
	isAreaFetching: boolean;
	countryList: any[]; // 需要业务相关人员补充，比如下列any类型，需要替换成具体类型
	isCountryFetching: boolean;
	sortLength: number;
	isStateFetching: boolean;
	stateList: any;
}
export const useCountryStore = defineStore("country", {
	state: (): CountryState => {
		return {
			areaList: [],
			isAreaFetching: false,
			countryList: [],
			isCountryFetching: false,
			sortLength: 0, // 有些情况下 热门国家和普通国家之间会有分割线，分割线所在length
			isStateFetching: false,
			stateList: {}
		};
	},
	getters: {},
	actions: {
		async getAreaData() {
			if (!this.areaList.length && !this.isAreaFetching) {
				this.isAreaFetching = true;
				const { data, error } = await useRequest.post(`/static`, {
					data: {
						method: "GET",
						url: `/api/website/siteRelation`,
						filterId: "siteRelation", // 标识字段
						moduleName: "siteRelation" // 模块名称
					}
				});
				// const { data, error } = await useRequest.get("/api/website/siteRelation");
				if (data && data.value) {
					const { list } = data.value.data;
					this.areaList = list[0]?.areaList?.length ? list[0]?.areaList : [];
					this.isAreaFetching = false;
				}
			}
		},
		async getCountryData() {
			if (!this.countryList.length && !this.isCountryFetching) {
				this.isCountryFetching = true;

				const { data, error } = await useRequest.get("/api/website/siteMain");

				if (data && data.value) {
					const { countryList } = data.value.data;
					this.countryList = countryList.source || [];
					this.sortLength = countryList.sortLength || 0;
					this.isCountryFetching = false;
				}
			}
		},
		async getState() {
			if (!this.countryList.length && !this.isStateFetching) {
				this.isStateFetching = true;
				const { data, error } = await useRequest.post("/api/website/getStatesOfCountries");
				if (data && data.value) {
					this.stateList = data.value.data;
					this.isStateFetching = false;
				}
			}
		}
	}
});
