import { default as indexmEGrlXaulvMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue?macro=true";
import { default as indexhyVw6HRdGpMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue?macro=true";
import { default as CategoryypR3p2J7oUMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue?macro=true";
import { default as CaseStudyjcfH5yfOOlMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue?macro=true";
import { default as CaseStudyListnCvoHbrg0NMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue?macro=true";
import { default as indexf5uDA9RnCjMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue?macro=true";
import { default as index2PLJnKB70FMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue?macro=true";
import { default as indexVS6rhRxMR9Meta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue?macro=true";
import { default as TestSpecials62RM70y4RmMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue?macro=true";
import { default as CheckoutL4iWvbTWhcMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue?macro=true";
import { default as CheckoutAgainstbvHoTEl2DnMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue?macro=true";
import { default as indexWdvZA9ReRaMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue?macro=true";
import { default as DemokuE6U4RBJyMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue?macro=true";
import { default as EthernetCableGuider88p1kFVbnMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue?macro=true";
import { default as ServiceZlTPuR3CvCMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue?macro=true";
import { default as PicosSoftwareON8sJolHVAMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue?macro=true";
import { default as ContactSalesMailVFesmlxezOMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue?macro=true";
import { default as HowToBuyOoPDa9yE3xMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue?macro=true";
import { default as ProductSupport6Kpk4M6PAXMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue?macro=true";
import { default as IndexgmJLTIBQsxMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue?macro=true";
import { default as TechnicalDocumentsqlFAdlLsfpMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue?macro=true";
import { default as SpecialsPfWi1kZJsvMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue?macro=true";
import { default as BlogjB5YVHTPnjMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue?macro=true";
import { default as CreateNewCasew1wMUjyMRqMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue?macro=true";
export default [
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___au",
    path: indexmEGrlXaulvMeta?.path ?? "/au",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___de",
    path: indexmEGrlXaulvMeta?.path ?? "/de",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___de-en",
    path: indexmEGrlXaulvMeta?.path ?? "/de-en",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___en",
    path: indexmEGrlXaulvMeta?.path ?? "/",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___es",
    path: indexmEGrlXaulvMeta?.path ?? "/es",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___mx",
    path: indexmEGrlXaulvMeta?.path ?? "/mx",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___fr",
    path: indexmEGrlXaulvMeta?.path ?? "/fr",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___it",
    path: indexmEGrlXaulvMeta?.path ?? "/it",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___jp",
    path: indexmEGrlXaulvMeta?.path ?? "/jp",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___ru",
    path: indexmEGrlXaulvMeta?.path ?? "/ru",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___sg",
    path: indexmEGrlXaulvMeta?.path ?? "/sg",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___uk",
    path: indexmEGrlXaulvMeta?.path ?? "/uk",
    meta: indexmEGrlXaulvMeta || {},
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___au",
    path: indexhyVw6HRdGpMeta?.path ?? "/au/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___de",
    path: indexhyVw6HRdGpMeta?.path ?? "/de/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___de-en",
    path: indexhyVw6HRdGpMeta?.path ?? "/de-en/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___en",
    path: indexhyVw6HRdGpMeta?.path ?? "/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___es",
    path: indexhyVw6HRdGpMeta?.path ?? "/es/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___mx",
    path: indexhyVw6HRdGpMeta?.path ?? "/mx/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___fr",
    path: indexhyVw6HRdGpMeta?.path ?? "/fr/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___it",
    path: indexhyVw6HRdGpMeta?.path ?? "/it/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___jp",
    path: indexhyVw6HRdGpMeta?.path ?? "/jp/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___ru",
    path: indexhyVw6HRdGpMeta?.path ?? "/ru/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___sg",
    path: indexhyVw6HRdGpMeta?.path ?? "/sg/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: indexhyVw6HRdGpMeta?.name ?? "index_demo___uk",
    path: indexhyVw6HRdGpMeta?.path ?? "/uk/index_demo",
    meta: indexhyVw6HRdGpMeta || {},
    alias: indexhyVw6HRdGpMeta?.alias || [],
    redirect: indexhyVw6HRdGpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/indexDemo/index.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___au",
    path: CategoryypR3p2J7oUMeta?.path ?? "/au/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___de",
    path: CategoryypR3p2J7oUMeta?.path ?? "/de/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___de-en",
    path: CategoryypR3p2J7oUMeta?.path ?? "/de-en/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___en",
    path: CategoryypR3p2J7oUMeta?.path ?? "/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___es",
    path: CategoryypR3p2J7oUMeta?.path ?? "/es/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___mx",
    path: CategoryypR3p2J7oUMeta?.path ?? "/mx/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___fr",
    path: CategoryypR3p2J7oUMeta?.path ?? "/fr/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___it",
    path: CategoryypR3p2J7oUMeta?.path ?? "/it/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___jp",
    path: CategoryypR3p2J7oUMeta?.path ?? "/jp/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___ru",
    path: CategoryypR3p2J7oUMeta?.path ?? "/ru/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___sg",
    path: CategoryypR3p2J7oUMeta?.path ?? "/sg/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___uk",
    path: CategoryypR3p2J7oUMeta?.path ?? "/uk/c/:id",
    meta: CategoryypR3p2J7oUMeta || {},
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___au",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/au/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___de",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/de/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___de-en",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/de-en/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___en",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___es",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/es/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___mx",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/mx/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___fr",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/fr/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___it",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/it/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___jp",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/jp/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___ru",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/ru/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___sg",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/sg/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___uk",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/uk/case-study/:id.html",
    meta: CaseStudyjcfH5yfOOlMeta || {},
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___au",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/au/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___de",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/de/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___de-en",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/de-en/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___en",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___es",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/es/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___mx",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/mx/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___fr",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/fr/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___it",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/it/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___jp",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/jp/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___ru",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/ru/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___sg",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/sg/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___uk",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/uk/case-study.html",
    meta: CaseStudyListnCvoHbrg0NMeta || {},
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___au",
    path: indexf5uDA9RnCjMeta?.path ?? "/au/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___de",
    path: indexf5uDA9RnCjMeta?.path ?? "/de/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___de-en",
    path: indexf5uDA9RnCjMeta?.path ?? "/de-en/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___en",
    path: indexf5uDA9RnCjMeta?.path ?? "/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___es",
    path: indexf5uDA9RnCjMeta?.path ?? "/es/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___mx",
    path: indexf5uDA9RnCjMeta?.path ?? "/mx/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___fr",
    path: indexf5uDA9RnCjMeta?.path ?? "/fr/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___it",
    path: indexf5uDA9RnCjMeta?.path ?? "/it/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___jp",
    path: indexf5uDA9RnCjMeta?.path ?? "/jp/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___ru",
    path: indexf5uDA9RnCjMeta?.path ?? "/ru/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___sg",
    path: indexf5uDA9RnCjMeta?.path ?? "/sg/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: indexf5uDA9RnCjMeta?.name ?? "testCom___uk",
    path: indexf5uDA9RnCjMeta?.path ?? "/uk/testCom",
    meta: indexf5uDA9RnCjMeta || {},
    alias: indexf5uDA9RnCjMeta?.alias || [],
    redirect: indexf5uDA9RnCjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TestCom/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___au",
    path: index2PLJnKB70FMeta?.path ?? "/au/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___de",
    path: index2PLJnKB70FMeta?.path ?? "/de/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___de-en",
    path: index2PLJnKB70FMeta?.path ?? "/de-en/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___en",
    path: index2PLJnKB70FMeta?.path ?? "/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___es",
    path: index2PLJnKB70FMeta?.path ?? "/es/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___mx",
    path: index2PLJnKB70FMeta?.path ?? "/mx/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___fr",
    path: index2PLJnKB70FMeta?.path ?? "/fr/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___it",
    path: index2PLJnKB70FMeta?.path ?? "/it/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___jp",
    path: index2PLJnKB70FMeta?.path ?? "/jp/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___ru",
    path: index2PLJnKB70FMeta?.path ?? "/ru/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___sg",
    path: index2PLJnKB70FMeta?.path ?? "/sg/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___uk",
    path: index2PLJnKB70FMeta?.path ?? "/uk/share_url/:code/:id",
    meta: index2PLJnKB70FMeta || {},
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___au",
    path: indexVS6rhRxMR9Meta?.path ?? "/au/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___de",
    path: indexVS6rhRxMR9Meta?.path ?? "/de/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___de-en",
    path: indexVS6rhRxMR9Meta?.path ?? "/de-en/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___en",
    path: indexVS6rhRxMR9Meta?.path ?? "/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___es",
    path: indexVS6rhRxMR9Meta?.path ?? "/es/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___mx",
    path: indexVS6rhRxMR9Meta?.path ?? "/mx/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___fr",
    path: indexVS6rhRxMR9Meta?.path ?? "/fr/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___it",
    path: indexVS6rhRxMR9Meta?.path ?? "/it/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___jp",
    path: indexVS6rhRxMR9Meta?.path ?? "/jp/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___ru",
    path: indexVS6rhRxMR9Meta?.path ?? "/ru/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___sg",
    path: indexVS6rhRxMR9Meta?.path ?? "/sg/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: indexVS6rhRxMR9Meta?.name ?? "compare___uk",
    path: indexVS6rhRxMR9Meta?.path ?? "/uk/compare",
    meta: indexVS6rhRxMR9Meta || {},
    alias: indexVS6rhRxMR9Meta?.alias || [],
    redirect: indexVS6rhRxMR9Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Compare/index.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___au",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/au/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___de",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/de/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___de-en",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/de-en/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___en",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___es",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/es/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___mx",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/mx/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___fr",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/fr/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___it",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/it/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___jp",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/jp/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___ru",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/ru/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___sg",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/sg/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: TestSpecials62RM70y4RmMeta?.name ?? "testSpecials___uk",
    path: TestSpecials62RM70y4RmMeta?.path ?? "/uk/testSpecials",
    meta: { ...(TestSpecials62RM70y4RmMeta || {}), ...{"group":"Checkout Page"} },
    alias: TestSpecials62RM70y4RmMeta?.alias || [],
    redirect: TestSpecials62RM70y4RmMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/TestSpecials/TestSpecials.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___au",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/au/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___de",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/de/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___de-en",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/de-en/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___en",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___es",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/es/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___mx",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/mx/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___fr",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/fr/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___it",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/it/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___jp",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/jp/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___ru",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/ru/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___sg",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/sg/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___uk",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/uk/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___au",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/au/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___de",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/de/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___de-en",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/de-en/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___en",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___es",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/es/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___mx",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/mx/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___fr",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/fr/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___it",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/it/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___jp",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/jp/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___ru",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/ru/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___sg",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/sg/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___uk",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/uk/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"group":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___au",
    path: indexWdvZA9ReRaMeta?.path ?? "/au/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___de",
    path: indexWdvZA9ReRaMeta?.path ?? "/de/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___de-en",
    path: indexWdvZA9ReRaMeta?.path ?? "/de-en/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___en",
    path: indexWdvZA9ReRaMeta?.path ?? "/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___es",
    path: indexWdvZA9ReRaMeta?.path ?? "/es/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___mx",
    path: indexWdvZA9ReRaMeta?.path ?? "/mx/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___fr",
    path: indexWdvZA9ReRaMeta?.path ?? "/fr/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___it",
    path: indexWdvZA9ReRaMeta?.path ?? "/it/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___jp",
    path: indexWdvZA9ReRaMeta?.path ?? "/jp/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___ru",
    path: indexWdvZA9ReRaMeta?.path ?? "/ru/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___sg",
    path: indexWdvZA9ReRaMeta?.path ?? "/sg/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___uk",
    path: indexWdvZA9ReRaMeta?.path ?? "/uk/tool/:id",
    meta: indexWdvZA9ReRaMeta || {},
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___au",
    path: DemokuE6U4RBJyMeta?.path ?? "/au/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___de",
    path: DemokuE6U4RBJyMeta?.path ?? "/de/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___de-en",
    path: DemokuE6U4RBJyMeta?.path ?? "/de-en/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___en",
    path: DemokuE6U4RBJyMeta?.path ?? "/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___es",
    path: DemokuE6U4RBJyMeta?.path ?? "/es/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___mx",
    path: DemokuE6U4RBJyMeta?.path ?? "/mx/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___fr",
    path: DemokuE6U4RBJyMeta?.path ?? "/fr/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___it",
    path: DemokuE6U4RBJyMeta?.path ?? "/it/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___jp",
    path: DemokuE6U4RBJyMeta?.path ?? "/jp/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___ru",
    path: DemokuE6U4RBJyMeta?.path ?? "/ru/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___sg",
    path: DemokuE6U4RBJyMeta?.path ?? "/sg/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: DemokuE6U4RBJyMeta?.name ?? "demo___uk",
    path: DemokuE6U4RBJyMeta?.path ?? "/uk/demo",
    meta: DemokuE6U4RBJyMeta || {},
    alias: DemokuE6U4RBJyMeta?.alias || [],
    redirect: DemokuE6U4RBJyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Demo/Demo.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___au",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/au/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___de",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/de/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___de-en",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/de-en/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___en",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___es",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/es/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___mx",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/mx/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___fr",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/fr/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___it",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/it/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___jp",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/jp/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___ru",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/ru/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___sg",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/sg/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___uk",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/uk/ethernet-cable-buying-guide.html",
    meta: EthernetCableGuider88p1kFVbnMeta || {},
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___au",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/au/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___de",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/de/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___de-en",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/de-en/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___en",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___es",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/es/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___mx",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/mx/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___fr",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/fr/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___it",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/it/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___jp",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/jp/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___ru",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/ru/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___sg",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/sg/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___uk",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/uk/fscare-customer-services.html",
    meta: ServiceZlTPuR3CvCMeta || {},
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___au",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/au/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___de",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/de/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___de-en",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/de-en/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___en",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___es",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/es/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___mx",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/mx/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___fr",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/fr/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___it",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/it/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___jp",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/jp/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___ru",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/ru/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___sg",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/sg/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___uk",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/uk/picos-software-customized.html",
    meta: PicosSoftwareON8sJolHVAMeta || {},
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___au",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/au/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___de",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/de/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___de-en",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/de-en/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___en",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___es",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/es/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___mx",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/mx/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___fr",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/fr/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___it",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/it/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___jp",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/jp/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___ru",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/ru/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___sg",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/sg/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___uk",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/uk/contact_sales_mail.html",
    meta: ContactSalesMailVFesmlxezOMeta || {},
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___au",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/au/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___de",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/de/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___de-en",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/de-en/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___en",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___es",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/es/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___mx",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/mx/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___fr",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/fr/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___it",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/it/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___jp",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/jp/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___ru",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/ru/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___sg",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/sg/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___uk",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/uk/how-to-buy.html",
    meta: HowToBuyOoPDa9yE3xMeta || {},
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___au",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/au/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___de",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/de/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___de-en",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/de-en/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___en",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___es",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/es/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___mx",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/mx/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___fr",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/fr/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___it",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/it/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___jp",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/jp/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___ru",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/ru/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___sg",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/sg/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___uk",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/uk/products_support.html",
    meta: ProductSupport6Kpk4M6PAXMeta || {},
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___au",
    path: IndexgmJLTIBQsxMeta?.path ?? "/au/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___de",
    path: IndexgmJLTIBQsxMeta?.path ?? "/de/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___de-en",
    path: IndexgmJLTIBQsxMeta?.path ?? "/de-en/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___en",
    path: IndexgmJLTIBQsxMeta?.path ?? "/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___es",
    path: IndexgmJLTIBQsxMeta?.path ?? "/es/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___mx",
    path: IndexgmJLTIBQsxMeta?.path ?? "/mx/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___fr",
    path: IndexgmJLTIBQsxMeta?.path ?? "/fr/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___it",
    path: IndexgmJLTIBQsxMeta?.path ?? "/it/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___jp",
    path: IndexgmJLTIBQsxMeta?.path ?? "/jp/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___ru",
    path: IndexgmJLTIBQsxMeta?.path ?? "/ru/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___sg",
    path: IndexgmJLTIBQsxMeta?.path ?? "/sg/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___uk",
    path: IndexgmJLTIBQsxMeta?.path ?? "/uk/products_support/preview_sheet.html",
    meta: IndexgmJLTIBQsxMeta || {},
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___au",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/au/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___de",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/de/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___de-en",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/de-en/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___en",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___es",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/es/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___mx",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/mx/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___fr",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/fr/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___it",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/it/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___jp",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/jp/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___ru",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/ru/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___sg",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/sg/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___uk",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/uk/technical_documents.html",
    meta: TechnicalDocumentsqlFAdlLsfpMeta || {},
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    path: "/technical_documents/search.html",
    name: "technical_documents_search"
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___au",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/au/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___de",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___de-en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de-en/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___es",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/es/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___mx",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/mx/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___fr",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/fr/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___it",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/it/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___jp",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/jp/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___ru",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/ru/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___sg",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/sg/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___uk",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/uk/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___au",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/au/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___de",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___de-en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de-en/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___es",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/es/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___mx",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/mx/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___fr",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/fr/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___it",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/it/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___jp",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/jp/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___ru",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/ru/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___sg",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/sg/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___uk",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/uk/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"group":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___au",
    path: BlogjB5YVHTPnjMeta?.path ?? "/au/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___de",
    path: BlogjB5YVHTPnjMeta?.path ?? "/de/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___de-en",
    path: BlogjB5YVHTPnjMeta?.path ?? "/de-en/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___en",
    path: BlogjB5YVHTPnjMeta?.path ?? "/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___es",
    path: BlogjB5YVHTPnjMeta?.path ?? "/es/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___mx",
    path: BlogjB5YVHTPnjMeta?.path ?? "/mx/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___fr",
    path: BlogjB5YVHTPnjMeta?.path ?? "/fr/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___it",
    path: BlogjB5YVHTPnjMeta?.path ?? "/it/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___jp",
    path: BlogjB5YVHTPnjMeta?.path ?? "/jp/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___ru",
    path: BlogjB5YVHTPnjMeta?.path ?? "/ru/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___sg",
    path: BlogjB5YVHTPnjMeta?.path ?? "/sg/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___uk",
    path: BlogjB5YVHTPnjMeta?.path ?? "/uk/blog.html",
    meta: BlogjB5YVHTPnjMeta || {},
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___au",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/au/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___de",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/de/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___de-en",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/de-en/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___en",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___es",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/es/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___mx",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/mx/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___fr",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/fr/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___it",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/it/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___jp",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/jp/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___ru",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/ru/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___sg",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/sg/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___uk",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/uk/create-new-support",
    meta: CreateNewCasew1wMUjyMRqMeta || {},
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  }
]